import Footer from 'ui/components/Footer';
import LandingPage from './components/LandingPage/LandingPage';
import LegacyHeader from './components/LegacyHeader';
import './styles/base.scss';
import './styles/layout.scss';

function App() {
	return (
		<div className="page-wrapper">
			<LegacyHeader />
			<main>
				<LandingPage />
			</main>
			<Footer />
		</div>
	);
}

export default App;
