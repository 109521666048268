import { ENVIRONMENT_SHARED } from 'environment';
import Button from 'ui/components/Button';

type LegacyHeaderProps = {};

const LegacyHeader = ({}: LegacyHeaderProps) => {
	return (
		<header className="header">
			<a href="/" className="header__home">
				<img
					className="header__logo"
					src="/img/cargois-logo.png"
					loading="lazy"
					alt="Go home"
				/>
			</a>

			<div className="header__actions">
				<Button href={ENVIRONMENT_SHARED.contactUsUrl}>Contact Us</Button>
			</div>
		</header>
	);
};

export default LegacyHeader;
