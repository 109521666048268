import { LogIn } from 'lucide-react';
import Button from 'ui/components/Button';

const NEW_CARGOIS_LINK = 'http://cargois.iata.org/';

type LandingPageProps = {};

const LandingPage = ({}: LandingPageProps) => {
	return (
		<div className="landing">
			<div className="landing__content">
				<div className="landing__box landing__box--light">
					<h1 className="landing__title">CargoIS Migration</h1>
					<div className="landing__details">
						<div className="landing__text">
							<p>
								We've upgraded to a brand new and better version of CargoIS! To
								access it, just head over to{' '}
								<a href={NEW_CARGOIS_LINK}>{NEW_CARGOIS_LINK}</a>.
							</p>
						</div>
					</div>

					<div className="landing__actions">
						<Button icon={LogIn} variant="primary" href={NEW_CARGOIS_LINK}>
							Go to New CargoIS
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LandingPage;
