import {
	AdminEnvironmentSettings,
	ClientPortalEnvironmentSettings,
	SharedEnvironmentSettings,
} from './environment-types';

export const ENVIRONMENT_CLIENT_PORTAL: ClientPortalEnvironmentSettings = {
	msal: {
		// This is the ONLY mandatory field that you need to supply.
		clientId: '2e0b8ed9-41cf-4fc0-befd-1816f3220381',
		// Defaults to "https://login.microsoftonline.com/common"
		authority:
			'https://iataazurenonprodb2c.b2clogin.com/iataazurenonprodb2c.onmicrosoft.com/b2c_1a_signup_signin_cisdev',
		// Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
		// Test whether window exists so we can re-use the config in our test setup
		redirectUri: typeof window !== 'undefined' ? window.location.origin : '',
		knownAuthority: 'https://iataazurenonprodb2c.b2clogin.com',
		scope: 'https://iataazurenonprodb2c.onmicrosoft.com/cargois-dev/App.User',
	},
};

export const ENVIRONMENT_ADMIN: AdminEnvironmentSettings = {
	msal: {
		// This is the ONLY mandatory field that you need to supply.
		clientId: '0f2d62a0-5fd9-45ff-b7ba-47a54299c8e7',
		// Defaults to "https://login.microsoftonline.com/common"
		authority:
			'https://iataazurenonprodb2c.b2clogin.com/iataazurenonprodb2c.onmicrosoft.com/b2c_1a_signup_signin_cisdev',
		// Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
		redirectUri: '/logged-in',
		knownAuthority: 'https://iataazurenonprodb2c.b2clogin.com',
		scope: 'https://iataazurenonprodb2c.onmicrosoft.com/cargois-dev/App.Admin',
	},
	staticReportEndpoint:
		/* NOTE: using non-secure HTTP (Not HTTPS) local URL here causes CORS issues */
		'https://dev-api.cargois.iata.org/static-reports',
	//'https://localhost:9198',
	dataMonitoringEndpoint: 'https://dev-api.cargois.iata.org/data-monitor',
};

export const ENVIRONMENT_SHARED: SharedEnvironmentSettings = {
	subscriptionsApiEndpoint:
		/* NOTE: using non-secure HTTP (Not HTTPS) local URL here causes CORS issues */
		'https://dev-api.cargois.iata.org',
	termsAndConditionsUrl:
		'https://iata--pprod.sandbox.my.site.com/csportal/s/support-view-category?category=Data_Products&topic=CargoIS&subtopic=Terminologies_and_Definitions_CargoIS',
	knowledgeHubUrl:
		'https://iata--pprod.sandbox.my.site.com/faq/s/faq-category?language=en_US&category=Data_Products&topic=CargoIS',
	contactUsUrl:
		'https://iata--pprod.sandbox.my.site.com/csportal/s/support-reach-us?topic=CargoIS',
	legacyPortalUrl: 'https://legacy.cargois.iata.org',
	featureFlags: {
		enableWebTool: true,
	},
	msal: {
		enableLogging: false,
	},
};
